/*
  Price and PriceSale is being used in:
  - the PDP Recommendation Carousels / Algolia Taxon Products CMS Module Product Cards
  - PLP Grid Product Cards & wishlist
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { formatMoney } from 'accounting-js';

const CardPrice = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  fontFamily: theme.font.caption,
  fontSize: 12,
  justifyContent: 'center',
  letterSpacing: 2,
  lineHeight: 1.4,
  textTransform: 'uppercase',
  [`@media screen and (min-width: ${theme.breakpoint.large})`]: {
    flexDirection: 'row'
  }
}));

const PriceOriginal = styled.span(() => (null));

const PriceSale = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '0.8rem',
  [PriceOriginal]: {
    color: theme.color.brandLightBlue,
    marginRight: '0.8rem',
    position: 'relative',
    '::before': {
      content: '""',
      width: '100%',
      height: 1,
      background: theme.color.brand,
      opacity: 0.5,
      position: 'absolute',
      left: 0,
      top: '50%'
    }
  }
}));

const PricePercentage = styled.span(({ theme }) => ({
  color: theme.color.brandA11yRed,
  textTransform: 'lowercase'
}));

const Price = ({ hit, ...props }) => {
  const {
    niceprice,
    min,
    max,
    on_sale,
    percent_off
  } = hit;
  // We'll conditionally re-assign these, so `let`
  let { price_min, price_max } = hit;
  price_min = price_min ?? min ?? 0;
  price_max = price_max ?? max ?? 0;

  if (on_sale && percent_off) {
    if (price_min !== price_max) {
      return (
        <CardPrice {...props}>
          <PriceSale>{`${formatMoney(price_min)} - ${formatMoney(price_max)}`}</PriceSale>
          <PricePercentage>{`UP TO ${percent_off}% OFF`}</PricePercentage>
        </CardPrice>
      );
    }

    return (
      <CardPrice {...props}>
        <PriceSale>
          <PriceOriginal>
            {formatMoney(price_min / (1 - (percent_off / 100)))}
          </PriceOriginal>
          {formatMoney(price_min)}
        </PriceSale>

        <PricePercentage>
          {`${percent_off}% off`}
        </PricePercentage>
      </CardPrice>
    );
  }

  if (price_min !== price_max) {
    return (
      <CardPrice {...props}>
        <PriceOriginal>{`${formatMoney(price_min)} - ${formatMoney(price_max)}`}</PriceOriginal>
      </CardPrice>
    );
  }

  return <CardPrice {...props}>{niceprice ?? formatMoney(price_min) ?? ''}</CardPrice>;
};

Price.propTypes = {
  hit: PropTypes.object.isRequired
};

export { PriceSale };

export default Price;
